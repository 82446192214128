<template>
  <div class="container" v-if="!showEdit">
    <div class="title">
      <span>{{ b_id ? '修改广告位' : '新增广告位' }}</span>
      <span class="back" @click="back"> <i class="iconfont icon-fanhui"></i> 返回 </span>
    </div>
    <el-divider></el-divider>
    <div class="wrap">
      <el-row>
        <el-col :xl="10" :lg="16" :md="20" :sm="24" :xs="24">
          <el-form
            :model="form"
            status-icon
            ref="form"
            label-width="100px"
            v-loading="loading"
            @submit.prevent
            :rules="formRules"
          >
            <!-- <el-form-item label="名称" prop="name">
              <el-input v-model="form.name" placeholder="请填写名称"></el-input>
            </el-form-item> -->
            <el-form-item label="标题" prop="title">
              <el-input v-model="form.title" placeholder="请填写标题" clearable></el-input>
            </el-form-item>
            <el-form-item label="平台" prop="application_id">
              <el-select v-model="form.application_id" placeholder="请选择平台">
                <el-option
                  v-for="item in applicationList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="主图" prop="img">
              <upload-imgs ref="uploadEle" :value="initData" :max-num="mainMaxNum" />
            </el-form-item> -->
            <el-form-item label="描述" prop="description">
              <el-input v-model="form.description" type="textarea" :rows="4" placeholder="请填写描述" clearable>
              </el-input>
            </el-form-item>
            <el-form-item class="submit" v-permission="['编辑广告位']">
              <el-button type="primary" @click="submitForm('form')">保 存</el-button>
              <el-button @click="resetForm('form')">重 置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-divider></el-divider>
      <div class="header">
        <div class="title">
          <span>广告列表</span>
          <el-button @click.prevent="addBannerItem" class="add-btn" type="primary" plain v-permission="['新增广告']"
            >新增广告</el-button
          >
        </div>
      </div>
      <el-row class="inner-container">
        <el-table stripe v-loading="loading" :data="bannerItems">
          <el-table-column prop="id" label="id" width="80"></el-table-column>
          <el-table-column prop="img" label="图片">
            <template #default="scope">
              <el-image class="img" :src="scope.row.img?.url" :z-index="2000" :initial-index="scope.$index"> </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="名称"></el-table-column>
          <!-- <el-table-column prop="keyword" label="关键字" min-width="200"></el-table-column> -->
          <el-table-column prop="type" label="类型">
            <template #default="scope">
              {{ scope.row.type ? '活动向导' : '无向导' }}
            </template>
          </el-table-column>
          <el-table-column prop="activity_id" label="活动id"></el-table-column>
          <el-table-column label="状态">
            <template #default="scope">
              {{ scope.row.is_show ? '展示' : '隐藏' }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button
                @click.prevent="handleEdit(scope.row)"
                type="primary"
                plain
                size="small"
                v-permission="['编辑广告']"
                >编辑</el-button
              >
              <el-button
                @click.prevent="handleDelete(scope.row)"
                type="danger"
                plain
                size="small"
                v-permission="['删除广告']"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </div>
  </div>
  <banner-item-edit
    v-else
    @editClose="editClose"
    :bannerId="b_id"
    :isCreate="isCreate"
    :editID="editID"
    :editItem="editItem"
  ></banner-item-edit>
</template>

<script>
import Banner from '@/model/banner'
import UploadImgs from '@/component/base/upload-image'
import appModel from '@/model/application'
import BannerItemEdit from './banner-item-edit'

export default {
  components: {
    UploadImgs,
    BannerItemEdit,
  },
  props: {
    bannerId: {
      type: Number,
    },
  },
  data() {
    const checkInfo = (rule, value, callback) => {
      if (!value || value.length == 0) {
        console.log(rule)
        ElMessage.warning('请将信息补充完整')
        callback(new Error('请将信息补充完整'))
      }
    }
    return {
      loading: false,
      b_id: 0,
      form: {
        name: 'b-1',
        application_id: '',
        title: '',
        description: '',
      },
      bannerItems: [],
      imgSrcList: [],
      mainMaxNum: 1,
      initData: [],
      isCreate: false,
      showEdit: false,
      editID: 0,
      editItem: {},
      applicationList: [],
      formRules: {
        title: [{ validator: checkInfo, trigger: 'blur', required: true }],
        application_id: [{ validator: checkInfo, trigger: 'blur', required: true }],
      },
    }
  },
  async mounted() {
    this.b_id = this.bannerId
    this.loading = true
    this.getApplicationList()
    if (this.b_id) this.getDetail()
    this.loading = false
  },
  methods: {
    // 获取上传的图片信息
    async getValue() {
      const val = await this.$refs.uploadEle.getValue()
      if (val && val.length > 0) {
        this.form.img_id = val[0].display
      }
    },
    // 获取广告位详情
    async getDetail() {
      const res = await Banner.getBanner(this.b_id)
      this.form = res
      this.initData = [{ id: res.id, display: res.img }]
      this.bannerItems = res.items
      this.initImgSrcList()
    },
    // 获取平台列表-全部
    async getApplicationList() {
      const res = await appModel.getApplicationList()
      this.applicationList = res.items
    },
    // 初始化图片
    initImgSrcList() {
      this.bannerItems.forEach(item => {
        if (!item.img) {
          return
        }
        this.imgSrcList.push(item.img.url)
      })
    },
    // 提交
    async submitForm(formName) {
      if (!this.form.application_id || !this.form.title) return this.$message.warning('请将信息补充完整')
      console.log(this.form)
      // this.$refs[formName].validate(async valid => {
      //   if (valid) {
      // console.log(111)
      // await this.getValue()
      const form = { ...this.form }
      let res
      if (this.b_id) {
        res = await Banner.editBanner(this.b_id, form)
        if (res.code < window.MAX_SUCCESS_CODE) {
          this.$message.success(`${res.message}`)
          this.$emit('detailClose')
        }
      } else {
        res = await Banner.addBanner(form)
        this.b_id = res.id
        console.log(this.b_id)
        this.$message.success('创建广告位成功')
      }
      // }
      // })
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    back() {
      this.$emit('detailClose')
    },
    addBannerItem() {
      console.log(this.b_id)
      if (!this.b_id) return this.$message.warning('请先创建广告位')
      this.isCreate = true
      this.editID = 0
      this.editItem = {}
      this.showEdit = true
    },
    editClose() {
      this.showEdit = false
      this.getDetail()
    },
    handleEdit(row) {
      this.isCreate = false
      this.editID = row.id
      this.editItem = row
      this.showEdit = true
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该广告, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const res = await Banner.deleteBannerItem(row.id)
        if (res.code < window.MAX_SUCCESS_CODE) {
          this.getDetail()
          this.$message({
            type: 'success',
            message: `${res.message}`,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-divider--horizontal {
  margin: 0;
}

.container {
  .title {
    height: 59px;
    line-height: 59px;
    color: $parent-title-color;
    font-size: 16px;
    font-weight: 500;
    text-indent: 40px;
    .add-btn {
      margin-left: 30px;
    }

    .back {
      float: right;
      margin-right: 40px;
      cursor: pointer;
    }
  }

  .wrap {
    padding: 20px;
  }

  .submit {
    float: left;
  }

  .inner-container {
    margin-bottom: 50px;
    .img {
      width: 100px;
      height: auto;
    }
  }
}
.col {
  display: flex;
  justify-content: space-between;
}
</style>
